// Load all js files from js dir
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../js/', true, /\.js$/));

import AOS from 'aos';

document.addEventListener('DOMContentLoaded', function() {
    AOS.init({
        startEvent: 'turbolinks:load' // if you are using turbolinks
    });
});


console.log("hello from kathi index.js")